import { Select } from "antd";
import React from "react";
import Info from "../../../GenericComponents/info";
import { Helpertexts } from "../../../Constants/HelperTexts";
import { UploadCustomPdb } from "./UploadCustomPdb";
import { StructureDb } from "../LabelizerLayout";

type ChoosePdbidOrFileProps = {
  selectValue: string | undefined;
  selectValueAfDb: string | undefined;
  loadProteinDB: (
    proteinDbId: string,
    fieldIndex: number,
    db: StructureDb
  ) => void;
  index: number;
  setSelectValue: (value: string | undefined) => void;
  setSelectValueAfDb: (value: string | undefined) => void;
  protein: string | undefined;
  changeProteinName: (name: string) => void;
  postProteinDB: (
    payload: { pdbID: string; chains: string; payload: any },
    fieldIndex: number
  ) => void;
};

export const ChoosePdbidOrFile: React.FC<ChoosePdbidOrFileProps> = (props) => {
  const {
    selectValue,
    selectValueAfDb,
    loadProteinDB,
    index,
    setSelectValue,
    setSelectValueAfDb,
    postProteinDB,
  } = props;

  return (
    <div className="choose-pdbidorfile-content">
      <div className="select-pdbid-frame">
        <div className="inputs">
          <div className="input">
            <div className="headline">
              Enter PDB-ID <Info infotext={Helpertexts["enterPdbId"]} />{" "}
            </div>
            <Select
              value={selectValue}
              className="select"
              placeholder="1ANF"
              onChange={(_val: any) => {}}
              onSearch={(searchInput: any) => {
                if (`${searchInput}`.length === 4) {
                  loadProteinDB(`${searchInput}`, index, StructureDb.PDB);
                }
                setSelectValue(searchInput);
              }}
              showSearch
            />
          </div>
          <div className="input">
            <div className="headline">
              Upload custom PDB <Info infotext={Helpertexts["customPdb"]} />{" "}
            </div>
            <UploadCustomPdb postProteinDB={postProteinDB} fieldIndex={index} />
          </div>
          <div className="input">
            <div className="headline">
              Enter AlphaFold-DB ID{" "}
              <Info infotext={Helpertexts["EnterAlphaFoldDbId"]} />{" "}
            </div>
            <input
              className="select"
              placeholder="Q8W3K0"
              onChange={(searchInput) => {
                setSelectValueAfDb(searchInput.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  loadProteinDB(
                    `${selectValueAfDb}`,
                    index,
                    StructureDb.AlphaFoldDB
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
